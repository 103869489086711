import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/translationsEn.js";
import translationTR from "./translations/translationsTr.js";
import translationDE from "./translations/translationsDe.js";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
  },
  "en-EN": {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
  "tr-TR": {
    translation: translationTR,
  },
  de: {
    translation: translationDE,
  },
  "de-DE": {
    translation: translationDE,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "tr",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
