import React, { useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import { GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";

import { darken, lighten } from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import Password from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import EditIcon from "@mui/icons-material/Edit";
import CompleteIcon from "@mui/icons-material/Check";
import FileCopyIcon from "@mui/icons-material/DisabledByDefault";
import { selectusers } from "store/usersSlice";

import TableContainer from "@mui/material/TableContainer";
const UsersTable = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(selectusers);

  const [pageSize, setPageSize] = useState(20);

  const handleOpenNew = () => {
    console.log("new user");
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const deletaData = React.useCallback(
    (id) => () => {
      let data = { ...id.row, dialogType: "appointments" };
      //   dispatch(openConfirmDialog(data));
    },
    []
  );

  const editStatus = React.useCallback(
    (id) => () => {
      let data = {
        appointmentId: id.row.appointmentId,
        status: id.row.status === "aktif" ? "iptal" : "aktif",
      };
      //   dispatch(updateAppointment(data));
    },
    []
  );
  const duplicateUser = React.useCallback(
    (id) => () => {
      console.log("delete user");
    },
    []
  );

  const columns = useMemo(() => [
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Düzenle"
          //   onClick={editStatusToComplete(params)}
          disabled={params.row.status === "pasif" ? true : false}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={params.row.status === "aktif" ? "Pasife al" : "Aktif et"}
          //   onClick={editStatus(params)}
          showInMenu
          //   disabled={params.row.status === "aktif" ? true : false}
        />,
        <GridActionsCellItem
          icon={<Password />}
          label="Şifreyi Sıfırla"
          //   onClick={deletaData(params)}
          showInMenu
        />,
      ],
    },
    {
      headerName: "Kullanıcı Adı",
      field: "userName",
      minWidth: 200,
      hideable: false,
    },
    {
      headerName: "Adı",
      field: "name",
      minWidth: 150,
      hideable: false,
    },
    {
      headerName: "Kullanıcı türü",
      field: "userType",
      minWidth: 150,
      hideable: false,
    },
    {
      headerName: "Oluşturma Tarihi",
      field: "createdDate",
      minWidth: 140,
      hideable: false,
      type: "date",
    },
    {
      headerName: "Oluşturan",
      field: "createdBy",
      minWidth: 140,
      hideable: false,
    },
    {
      headerName: "Durum",
      field: "status",
      resizable: true,
      minWidth: 150,
    },

    [deletaData, editStatus, duplicateUser],
  ]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Kullanıcılar</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                Kullanıcı listesi
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
            <MDButton variant="gradient" color="info" onClick={handleOpenNew}>
              <Icon>add</Icon>&nbsp; Yeni Kullanıcı Ekle
            </MDButton>
          </Grid>
        </Grid>
        <div style={{ height: 400, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <Box
                sx={{
                  height: 400,
                  width: 1,
                  "& .super-app-theme--iptal": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode
                      ),
                  },
                  "& .super-app-theme--tamamlandi": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.info.main,
                        theme.palette.mode
                      ),
                  },
                }}
              >
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[20, 50, 100]}
                  pagination
                  autoHeight
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.status}`
                  }
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                />
              </Box>
            </div>
          </div>
        </div>
        <div style={{ height: 400, width: "100%" }}></div>
      </MDBox>
    </TableContainer>
  );
};

UsersTable.propTypes = {};

export default UsersTable;
