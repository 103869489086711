import React from "react";
import ReactLoading from "react-loading";

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto"
  // height: "100%",
};

const spinnerStyle = {
  width: "80px",
  height: "80px",
  marginTop:"350px"
}

const LoadingComponent = (props) => {
  return (
    <div  style={containerStyle}>
      <div>

      loading
      </div>
    </div>
  );
};

export default LoadingComponent;
