import React from "react";
import PropTypes from "prop-types";

import AdminLayout from "pages/main/layout/AdminLayout";
const AdminDashboard = (props) => {
  return (
    <AdminLayout>
      <h3>Songs </h3>
      <p>Sons adding updating vs.</p>
    </AdminLayout>
  );
};

AdminDashboard.propTypes = {};

export default AdminDashboard;
