import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import { toastr } from "react-redux-toastr";
export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async () => {
    const response = await axiosConfig.get(`/api/categories`);
    // const response = await axios.get(`${proxy}/api/categories`)

    let { data } = await response.data;
    return data;
  }
);

export const getCategoriesWithoutDepartments = createAsyncThunk(
  "departments/getCategoriesWithoutDepartments",
  async (params) => {
    const response = await axiosConfig.get(
      `/api/categories/without-department/${params.companyId}/${params.departmentId}`
    );
    // const response = await axios.get(`${proxy}/api/departments`)
    let { data } = await response.data;
    return data;
  }
);

export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (category, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/categories`, category);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
      return null;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async (category, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/categories/${category.categoryId}`,
      category
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    }
    return null;
  }
);

export const removeCategory = createAsyncThunk(
  "categories/removeCategory",
  async (categoryId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/categories/${categoryId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return categoryId;
    }
    return categoryId;
  }
);

const categoriesAdapter = createEntityAdapter({
  selectId: (category) => category.categoryId,
});

export const { selectAll: selectcategories, selectById: selectcategoriesById } =
  categoriesAdapter.getSelectors((state) => state.categories);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    categoryDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
  }),
  reducers: {
    setCategorySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openEditCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditCategoryDialog: (state, action) => {
      state.categoryDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCategory.fulfilled]: categoriesAdapter.upsertOne,
    [addCategory.fulfilled]: categoriesAdapter.addOne,
    [removeCategory.fulfilled]: (state, action) =>
      categoriesAdapter.removeOne(state, action.payload),
    [getCategories.fulfilled]: categoriesAdapter.setAll,
    [getCategoriesWithoutDepartments.fulfilled]: categoriesAdapter.setAll,
  },
});

export const {
  setCategorySearchText,
  openNewCategoryDialog,
  closeNewCategoryDialog,
  openEditCategoryDialog,
  closeEditCategoryDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
