import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AdminLayout from "pages/main/layout/AdminLayout";

import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import companiesData from "dummy/companiesData";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormField from "layouts/applications/wizard/components/FormField";
import { t } from "i18next";
import { getCompanies } from "store/companiesSlice";
import { selectcompanies } from "store/companiesSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SelectCompanyHeader from "components/custom/SelectCompanyHeader";
import { getDepartmentsWithoutCompanies } from "store/departmentsSlice";
import { getCompanyDepartmentsByCompany } from "store/companyDepartmentsSlice";
import CompanyDepartmentsDashboard from "./CompanyDepartmentsDashboard";
import { getDepartmentCategoriesByCompany } from "store/departmentCategoriesSlice";
import { getUserAuthsByCompany } from "store/userAuthsSlice";

const CompaniesDashboard = (props) => {
  const dispatch = useDispatch();

  const [companiesLoading, setcompaniesLoading] = useState(true);
  const [
    departmentsWithoutCompaniesLoading,
    setdepartmentsWithoutCompaniesLoading,
  ] = useState(false);
  const [companyDepartmentsLoading, setcompanyDepartmentsLoading] =
    useState(false);
  const [departmentCategoriesLoading, setdepartmentCategoriesLoading] =
    useState(false);
  const [departmentUsersLoading, setdepartmentUsersLoading] = useState(false);
  const [value, setValue] = useState({
    id: 0,
    name: "",
  });

  useEffect(() => {
    dispatch(getCompanies()).then(() => setcompaniesLoading(false));
  }, []);
  useEffect(() => {
    setdepartmentsWithoutCompaniesLoading(true);
    setdepartmentCategoriesLoading(true);
    setdepartmentUsersLoading(true);
    dispatch(getDepartmentsWithoutCompanies(value.id)).then(() =>
      setdepartmentsWithoutCompaniesLoading(false)
    );
    dispatch(getCompanyDepartmentsByCompany(value.id)).then(() =>
      setcompanyDepartmentsLoading(false)
    );
    dispatch(getDepartmentCategoriesByCompany(value.id)).then(() =>
      setdepartmentCategoriesLoading(false)
    );
    dispatch(getUserAuthsByCompany(value.id)).then(() =>
      setdepartmentUsersLoading(false)
    );
  }, [value, setValue]);

  if (
    companiesLoading ||
    departmentsWithoutCompaniesLoading ||
    companyDepartmentsLoading ||
    departmentCategoriesLoading ||
    departmentUsersLoading
  ) {
    return <h1>Loading</h1>;
  }

  return (
    <AdminLayout>
      <SelectCompanyHeader value={value} setValue={setValue} />
      {value && value.id && value.id > 0 && (
        <CompanyDepartmentsDashboard selectedCompany={value.id} />
      )}
    </AdminLayout>
  );
};

export default CompaniesDashboard;
