import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AdminLayout from "pages/main/layout/AdminLayout";
import { Card, Grid } from "@mui/material";
import UsersTable from "./UsersTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { getUsers } from "store/usersSlice";
const UsersDashboard = (props) => {
  const dispatch = useDispatch();

  const [usersLoading, setusersLoading] = useState(true);
  useEffect(() => {
    dispatch(getUsers()).then(() => setusersLoading(false));
  }, []);

  return (
    <AdminLayout>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Kullanıcılar
          </MDTypography>
          <MDTypography variant="button" color="text">
            Kullanıcıları görüntüleme, durumlarını değiştirme , şifre sıfırlama,
            yeni kullanıcı ekleme işlemlerinizi yapın
          </MDTypography>
        </MDBox>
        <MDBox p={3} lineHeight={1}>
          <UsersTable />
        </MDBox>
      </Card>
    </AdminLayout>
  );
};

UsersDashboard.propTypes = {};

export default UsersDashboard;
