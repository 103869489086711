import { useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";

import { useForm } from "features/hooks";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "store/categoriesSlice";
import { addCompany } from "store/companiesSlice";
import { addDepartment } from "store/departmentsSlice";
const defaultFormState = {
  name: "",
  description: "",
  status: "aktif",
};

const AddNewObjectForm = ({ title, id, formType }) => {
  const dispatch = useDispatch();
  const [newCardForm, setNewCardForm] = useState(false);

  const [formValue, setFormValue] = useState("");

  const userName = useSelector((state) => state.auth.userName);
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { t } = useTranslation();

  const openNewCardForm = (event, id) => setNewCardForm(id);
  const closeNewCardForm = () => setNewCardForm(false);

  const handleAddNewItem = () => {
    let data = { ...form, createdBy: userName };

    switch (formType) {
      case "category":
        let categoryData = {
          ...form,
          createdBy: userName,
          categoryName: form.name,
          categoryDescription: form.description,
        };

        dispatch(addCategory(categoryData));
        break;

      case "company":
        let companyData = {
          ...form,
          createdBy: userName,
          companyName: form.name,
          companyDescription: form.description,
        };

        console.log();
        dispatch(addCompany(companyData));
        break;

      case "department":
        let departmentData = {
          ...form,
          createdBy: userName,
          departmentName: form.name,
          departmentDescription: form.description,
        };

        dispatch(addDepartment(departmentData));
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <MDTypography variant="h6">{t("ADD_NEW")}</MDTypography>
        <MDButton
          size="small"
          iconOnly
          onClick={(event) => openNewCardForm(event, id)}
        >
          <Icon
            sx={{
              fontWeight: "bold",
              color: ({ palette: { dark } }) => dark.main,
            }}
          >
            add
          </Icon>
        </MDButton>
      </MDBox>
      {newCardForm === id ? (
        <MDBox my={2.5}>
          <MDBox>
            <MDInput
              value={form.name}
              id="name"
              name="name"
              onChange={handleChange}
              fullWidth
              label={t("NAME")}
            />
          </MDBox>
          <MDBox mt={2}>
            <MDInput
              value={form.description}
              id="description"
              name="description"
              onChange={handleChange}
              fullWidth
              label={t("DESCRIPTION")}
            />
          </MDBox>
          <MDBox display="flex" mt={2}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => {
                handleAddNewItem();
                setForm(defaultFormState);
                closeNewCardForm();
              }}
            >
              {t("ADD")}
            </MDButton>
            <MDBox ml={1}>
              <MDButton
                variant="gradient"
                color="light"
                size="small"
                onClick={closeNewCardForm}
              >
                {t("CANCEL")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
    </>
  );
};

AddNewObjectForm.propTypes = {};

export default AddNewObjectForm;
