import { combineReducers } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import auth from "./auth/authSlice";
import confirmDialog from "./common/confirmslice";
import companies from "./companiesSlice";
import departments from "./departmentsSlice";
import categories from "./categoriesSlice";
import companyDepartments from "./companyDepartmentsSlice";
import departmentCategories from "./departmentCategoriesSlice";
import userAuths from "./userAuthsSlice";
import users from "./usersSlice";
import i18n from "./i18nSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    i18n,
    toastr: toastrReducer,
    confirmDialog,
    companies,
    departments,
    categories,
    companyDepartments,
    departmentCategories,
    users,
    userAuths,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
