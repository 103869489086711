import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DepartmentCard from "./DepartmentCard";

// Project page components

// Images
import AddObjectFormWithSelect from "components/custom/AddObjectFormWithSelect";
import cuid from "cuid";
import categoriesData from "dummy/categoriesData";
import companiesData from "dummy/companiesData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectcompanyDepartments } from "store/companyDepartmentsSlice";
import { selectdepartments } from "store/departmentsSlice";
import { Card } from "@mui/material";
import { selectdepartmentCategories } from "store/departmentCategoriesSlice";
import { selectuserAuths } from "store/userAuthsSlice";

const CompanyDepartmentsDashboard = ({ selectedCompany }) => {
  const { t } = useTranslation();
  const companyDepartmentsData = useSelector(selectcompanyDepartments);
  const departmentsWithoutCompany = useSelector(selectdepartments);

  const [newCardForm, setNewCardForm] = useState(false);

  const departmantCategories = useSelector(selectdepartmentCategories);
  const departmenUsers = useSelector(selectuserAuths);

  let departmentsCard =
    companyDepartmentsData &&
    Array.isArray(companyDepartmentsData) &&
    companyDepartmentsData.map((line) => {
      let categoriesData =
        departmantCategories && Array.isArray(departmantCategories)
          ? departmantCategories.filter(
              (f) => f.departmentId === line.departmentId
            )
          : [];

      let usersData =
        departmenUsers && Array.isArray(departmenUsers)
          ? departmenUsers.filter((f) => f.departmentId === line.departmentId)
          : [];
      return (
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={1.5} mt={1.5}>
            <DepartmentCard
              key={cuid()}
              title={line.departmentName}
              description={line.departmentDescription}
              categories={categoriesData}
              users={usersData}
              selectedCompany={selectedCompany}
              selectedDepartment={line.departmentId}
            />
          </MDBox>
        </Grid>
      );
    });
  return (
    <MDBox pb={3}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={7}>
          <MDBox mb={1}>
            <MDTypography variant="h5">
              {t("COMPANIES_DEPARTMENT_HEADER")}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text">
              {t("COMPANIES_DEPARTMENT_DESCRIPTION")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => setNewCardForm(selectedCompany)}
          >
            <Icon>add</Icon>&nbsp; Add New
          </MDButton>
        </Grid>
      </Grid>
      <MDBox mt={5}>
        {newCardForm === selectedCompany && (
          <MDBox position="relative" mb={5} mt={10}>
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <AddObjectFormWithSelect
                newCardForm={newCardForm}
                setNewCardForm={setNewCardForm}
                id={selectedCompany}
                optionsData={departmentsWithoutCompany}
                selectText={t("SELECT_DEPARTMENT_FOR_COMPANY")}
                formHeader={t("ATTACH_DEPARTMENT_FOR_COMPANY")}
                formType="companyDepartment"
                companyId={selectedCompany}
              />
            </Card>
          </MDBox>
        )}
        <Grid container spacing={3}>
          {departmentsCard}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default CompanyDepartmentsDashboard;
