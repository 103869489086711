/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormField from "layouts/applications/wizard/components/FormField";
import { selectcompanies } from "store/companiesSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function SelectCompanyHeader({ children, value, setValue }) {
  const companiesData = useSelector(selectcompanies);

  const { t } = useTranslation();

  return (
    <MDBox position="relative" mb={5} mt={10}>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Icon
              fontSize="large"
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
              store
            </Icon>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Autocomplete
                value={value}
                options={companiesData}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={t("SELECT_COMPANY")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue.name === "string") {
                    setValue({
                      id: newValue.id,
                      name: newValue.name,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                      name: newValue.inputValue,
                    });
                  } else {
                    setValue(newValue);
                  }
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
SelectCompanyHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
SelectCompanyHeader.propTypes = {
  children: PropTypes.node,
};

export default SelectCompanyHeader;
