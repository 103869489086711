/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "pages/webPages/layout";

// Image
import bgImage from "assets/files/back.jpg";

//translation
import { useTranslation } from "react-i18next";

import { useForm } from "features/hooks";
import { login } from "store/auth/authSlice";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import MuiLink from "@mui/material/Link";

const defaultFormState = {
  userName: "",
  password: "",
};

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);

  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const [loading, setLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(login(form)).then(() => {
      setTimeout(() => setLoading(false), 1000);
    });
  };

  return (
    <BasicLayout image={bgImage}
    >
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("sign_in_header")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label={t("email")}
                fullWidth
                value={form.userName}
                onChange={handleChange}
                id="userName"
                name="userName"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("password")}
                fullWidth
                value={form.password}
                id="password"
                name="password"
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleLogin}
              >
                {t("sign_in_header")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
