import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AdminLayout from "pages/main/layout/AdminLayout";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Card, CardHeader } from "@mui/material";
import ObjectList from "components/custom/ObjectList";

import categoriesListData from "layouts/pages/widgets/data/categoriesListData";

import { useTranslation } from "react-i18next";
import { selectcompanies } from "store/companiesSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCompanies } from "store/companiesSlice";
import { getDepartments } from "store/departmentsSlice";
import { selectdepartments } from "store/departmentsSlice";
import { getCategories } from "store/categoriesSlice";
import { selectcategories } from "store/categoriesSlice";

const MasterDataDashboard = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const companiesData = useSelector(selectcompanies);
  const departmentsData = useSelector(selectdepartments);
  const categoriesData = useSelector(selectcategories);
  const [companiesLoading, setcompaniesLoading] = useState(true);
  const [categoriesLoading, setcategoriesLoading] = useState(true);
  const [departmentsLoading, setdepartmentsLoading] = useState(true);

  useEffect(() => {
    dispatch(getCompanies()).then(() => setcompaniesLoading(false));
    dispatch(getDepartments()).then(() => setdepartmentsLoading(false));
    dispatch(getCategories()).then(() => setcategoriesLoading(false));
  }, []);

  if (companiesLoading || departmentsLoading || categoriesLoading) {
    return <h1>Loading</h1>;
  }

  return (
    <AdminLayout>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <ObjectList
            title={t("COMPANIES")}
            data={companiesData}
            formType="company"
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <ObjectList
            title={t("DEPARTMENTS")}
            data={departmentsData}
            formType="department"
          />
        </Grid>
        <Grid item xs={12} xl={4}>
          <ObjectList
            title={t("CATEGORIES")}
            data={categoriesData}
            formType="category"
          />
        </Grid>
      </Grid>
    </AdminLayout>
  );
};

MasterDataDashboard.propTypes = {};

export default MasterDataDashboard;
