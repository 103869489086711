// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import AdminDashboard from "pages/admin/dashboard/AdminDashboard";
import UsersDashboard from "pages/admin/users/UsersDashboard";
import CompaniesDashboard from "pages/admin/companies/CompaniesDashboard";
import CategoriesDashboard from "pages/admin/categories/CategoriesDashboard";
import DepartmentsDashboard from "pages/admin/departments/DepartmentsDashboard";
import MasterDataDashboard from "pages/admin/masterData/MasterDataDashboard";

const routes = [
  {
    type: "collapse",
    name: "ÜNÜVAR",
    key: "lasolits",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        route: "/dashboards/analytics",
        key: "Dashboard",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        component: <AdminDashboard />,
      },
      {
        name: "Kullanıcılar",
        route: "/kullanicilar",
        key: "kullanicilar",
        icon: <Icon fontSize="medium">user</Icon>,
        component: <UsersDashboard />,
      },
      {
        name: "Master Data",
        route: "/master-data",
        key: "master-data",
        icon: <Icon fontSize="medium">user</Icon>,
        component: <MasterDataDashboard />,
      },
      {
        name: "Şirketler",
        route: "/sirketler",
        key: "sirketler",
        component: <CompaniesDashboard />,
        icon: <Icon fontSize="medium">keyboard_voice</Icon>,
      },
      {
        name: "Kategoriler",
        route: "/kategoriler",
        key: "kategoriler",
        component: <CategoriesDashboard />,
        icon: <Icon fontSize="medium">favorite</Icon>,
      },
      {
        name: "Bölümler",
        route: "/bolumler",
        key: "Bölümler",
        component: <DepartmentsDashboard />,
        icon: <Icon fontSize="medium">favorite</Icon>,
      },
    ],
  },
];

export default routes;
