import React, { useTransition } from "react";
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useForm } from "features/hooks";
import { useEffect } from "react";

import _ from "features/@lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddNewObjectForm from "./AddNewObjectForm";
import { Button } from "@mui/material";
import EditObjectForm from "./EditObjectForm";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AddObjectFormWithSelect from "./AddObjectFormWithSelect";
import categoriesData from "dummy/categoriesData";
import AddNewObjectFormWithSelectAndButton from "./AddNewObjectFormWithSelectAndButton";
import { useSelector } from "react-redux";
import { selectcategories } from "store/categoriesSlice";
import { useDispatch } from "react-redux";
import { getCategoriesWithoutDepartments } from "store/categoriesSlice";
import { getUsersWithoutDepartments } from "store/usersSlice";
import { selectusers } from "store/usersSlice";
import MDBadge from "components/MDBadge";
const defaultFormState = {
  search: "",
};
const CategoryList = ({
  title,
  data,
  formType,
  selectedCompany,
  selectedDepartment,
}) => {
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [values, setValues] = useState(data);
  const [editCardForm, setEditCardForm] = useState(false);

  const [newCardForm, setNewCardForm] = useState(false);
  const openEditCardForm = (event, id) => setEditCardForm(id);

  const closeEditCardForm = () => setEditCardForm(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const categories = useSelector(selectcategories);
  const users = useSelector(selectusers);

  useEffect(() => {
    if (form.search.length !== 0) {
      setValues(
        _.filter(data, (item) =>
          item.name.toLowerCase().includes(form.search.toLowerCase())
        )
      );
    } else {
      setValues(data);
    }
  }, [data, form.search]);

  useEffect(() => {
    switch (formType) {
      case "category":
        dispatch(
          getCategoriesWithoutDepartments({
            companyId: selectedCompany,
            departmentId: selectedDepartment,
          })
        );
        break;

      case "user":
        dispatch(
          getUsersWithoutDepartments({
            companyId: selectedCompany,
            departmentId: selectedDepartment,
          })
        );
        break;
      default:
        break;
    }
  }, [
    formType,
    selectedCompany,
    selectedDepartment,
    newCardForm,
    setNewCardForm,
  ]);

  const handleEditObjectData = (data) => {
    setEditCardForm(false);
  };
  const renderItems = values.map(
    ({ color, icon, name, description, route, id, status }, key) => (
      <>
        {editCardForm && editCardForm === id ? (
          <>
            <MDTypography variant="caption" color="text">
              Düzenle
            </MDTypography>
            <EditObjectForm
              id={id}
              name={name}
              description={description}
              status={status}
              closeEditCardForm={closeEditCardForm}
              setEditCardForm={closeEditCardForm}
              formType={formType}
            />
          </>
        ) : (
          <>
            <MDBox
              key={name}
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="lg"
              py={1}
              pr={3}
              mb={values.length - 1 === key ? 0 : 1}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    variant="button"
                    color={color}
                    fontWeight="medium"
                    gutterBottom
                  >
                    {name}
                  </MDTypography>
                  <MDTypography variant="caption" color="text">
                    {description}
                  </MDTypography>

                  <MDBadge
                    badgeContent={status}
                    container
                    color={status === "aktif" ? "main" : "primary"}
                  />
                </MDBox> 
              </MDBox>

              <MDBox
                display="flex"
                onClick={(event) => openEditCardForm(event, id)}
              >
                <MDTypography
                  component={Button}
                  variant="button"
                  color={color}
                  sx={{
                    lineHeight: 0,
                    transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
                    p: 0.5,
                    "&:hover, &:focus": {
                      transform: "translateX(5px)",
                    },
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                </MDTypography>
              </MDBox>
            </MDBox>
          </>
        )}
      </>
    )
  );
  let selectText =
    formType === "category"
      ? t("SELECT_CATEGORY_FOR_DEPARTMENT")
      : t("SELECT_USER_FOR_DEPARTMENT");

      let optionsData = formType ==='category' ? categories : users;
  return (
    <Card>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
        </AccordionSummary>
        <AccordionDetails>

          <AddNewObjectFormWithSelectAndButton
            newCardForm={newCardForm}
            setNewCardForm={setNewCardForm}
            id={selectedDepartment}
            optionsData={optionsData}
            selectText={selectText}
            formType={formType}
            companyId={selectedCompany}
            departmentId={selectedDepartment}
          />
          <MDBox p={2}>
            <MDInput
              type="search"
              label={t("SEARCH")}
              value={form.search}
              id="search"
              name="search"
              onChange={handleChange}
              fullWidth
            />
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
            >
              {renderItems}
            </MDBox>
          </MDBox>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default CategoryList;
