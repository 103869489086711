import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import { toastr } from "react-redux-toastr";

export const getCompanyDepartments = createAsyncThunk(
  "companyDepartments/getCompanyDepartments",
  async () => {
    const response = await axiosConfig.get(`/api/companyDepartments`);
    // const response = await axios.get(`${proxy}/api/companyDepartments`)

    let { data } = await response.data;
    return data;
  }
);
export const getCompanyDepartmentsByCompany = createAsyncThunk(
  "companyDepartments/getCompanyDepartmentsByCompany",
  async (companyId) => {
    const response = await axiosConfig.get(
      `/api/departments/companies/${companyId}`
    );
    // const response = await axios.get(`${proxy}/api/companyDepartments`)

    let { data } = await response.data;
    return data;
  }
);
export const addCompanyDepartment = createAsyncThunk(
  "companyDepartments/addCompanyDepartment",
  async (companyDepartment, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/company-departments`,
        companyDepartment
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        console.log("getState", getState);
        return data;
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
      return null;
    }
  }
);

export const updateCompanyDepartment = createAsyncThunk(
  "companyDepartments/updateCompanyDepartment",
  async (params, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/company-departments/${params.companyId}/${params.departmentId}`,
      params
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    }
    return null;
  }
);

export const removeCompanyDepartment = createAsyncThunk(
  "companyDepartments/removeCompanyDepartment",
  async (params, { dispatch, getState }) => {
    let response = await axiosConfig.delete(
      `/api/company-departments/${params.companyId}/${params.departmentId}`
    );
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return params.companyId && params.departmentId;
    }
    return params;
  }
);

const companyDepartmentsAdapter = createEntityAdapter({
  selectId: (companyDepartment) => companyDepartment.departmentId,
});

export const {
  selectAll: selectcompanyDepartments,
  selectById: selectcompanyDepartmentsById,
} = companyDepartmentsAdapter.getSelectors((state) => state.companyDepartments);

const companyDepartmentsSlice = createSlice({
  name: "companyDepartments",
  initialState: companyDepartmentsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    companyDepartmentDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
  }),
  reducers: {
    setCompanyDepartmentSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewCompanyDepartmentDialog: (state, action) => {
      state.companyDepartmentDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewCompanyDepartmentDialog: (state, action) => {
      state.companyDepartmentDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openEditCompanyDepartmentDialog: (state, action) => {
      state.companyDepartmentDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditCompanyDepartmentDialog: (state, action) => {
      state.companyDepartmentDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCompanyDepartment.fulfilled]: companyDepartmentsAdapter.upsertOne,
    [addCompanyDepartment.fulfilled]: companyDepartmentsAdapter.addOne,
    [removeCompanyDepartment.fulfilled]: (state, action) =>
      companyDepartmentsAdapter.removeOne(state, action.payload),
    [getCompanyDepartments.fulfilled]: companyDepartmentsAdapter.setAll,
    [getCompanyDepartmentsByCompany.fulfilled]:
      companyDepartmentsAdapter.setAll,
  },
});

export const {
  setCompanyDepartmentSearchText,
  openNewCompanyDepartmentDialog,
  closeNewCompanyDepartmentDialog,
  openEditCompanyDepartmentDialog,
  closeEditCompanyDepartmentDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = companyDepartmentsSlice.actions;

export default companyDepartmentsSlice.reducer;
