import React, { useTransition } from "react";
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useForm } from "features/hooks";
import { useEffect } from "react";

import _ from "features/@lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddNewObjectForm from "./AddNewObjectForm";
import { Button } from "@mui/material";
import EditObjectForm from "./EditObjectForm";

import MDBadge from "components/MDBadge";

const defaultFormState = {
  search: "",
};
const ObjectList = ({ title, data, formType }) => {
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);

  const [values, setValues] = useState(data);
  const [editCardForm, setEditCardForm] = useState(false);

  const openEditCardForm = (event, id) => setEditCardForm(id);

  const closeEditCardForm = () => setEditCardForm(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (form.search.length !== 0) {
      setValues(
        _.filter(data, (item) =>
          item.name.toLowerCase().includes(form.search.toLowerCase())
        )
      );
    } else {
      setValues(data);
    }
  }, [data, form.search]);

  const handleEditObjectData = (data) => {
    setEditCardForm(false);
  };
  const renderItems = values.map(
    ({ color, icon, name, description, route, id, status }, key) => (
      <>
        {editCardForm && editCardForm === id ? (
          <>
            <MDTypography variant="caption" color="text">
              Düzenle
            </MDTypography>
            <EditObjectForm
              id={id}
              name={name}
              description={description}
              status={status}
              closeEditCardForm={closeEditCardForm}
              setEditCardForm={closeEditCardForm}
              formType={formType}
            />
          </>
        ) : (
          <>
            <MDBox
              key={name}
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="lg"
              py={1}
              pr={2}
              mb={values.length - 1 === key ? 0 : 1}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox
                  display="grid"
                  alignItems="center"
                  justifyContent="center"
                  bgColor={color}
                  borderRadius="lg"
                  shadow="md"
                  color="white"
                  width="2rem"
                  height="2rem"
                  mr={2}
                  variant="gradient"
                  fontSize="0.875rem"
                >
                  <Icon
                    sx={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {icon}
                  </Icon>
                </MDBox>

                <MDBox display="flex" flexDirection="column">
                
                  <MDTypography
                    variant="button"
                    color={color}
                    fontWeight="medium"
                    gutterBottom
                  >
                    {name}
                  </MDTypography>
                  <MDTypography variant="caption" color="text">
                    {description}
                  </MDTypography>
                  <MDBadge
                    badgeContent={status}
                    container
                    color={status === "aktif" ? "main" : "primary"}
                  />
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                onClick={(event) => openEditCardForm(event, id)}
              >
                <MDTypography
                  component={Button}
                  variant="button"
                  color={color}
                  to={route}
                  sx={{
                    lineHeight: 0,
                    transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
                    p: 0.5,

                    "&:hover, &:focus": {
                      transform: "translateX(5px)",
                    },
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                </MDTypography>
              </MDBox>
            </MDBox>
          </>
        )}
      </>
    )
  );

  return (
    <Card>
      <MDBox pt={2} px={2}>
        <AddNewObjectForm formType={formType} />
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDInput
          type="search"
          label={t("SEARCH")}
          value={form.search}
          id="search"
          name="search"
          onChange={handleChange}
          fullWidth
        />
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ObjectList;
