/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { t } from "i18next";
import { Button } from "@mui/material";
import CategoriesList from "examples/Lists/CategoriesList";
import categoriesData from "dummy/categoriesData";
import CategoryList from "components/custom/CategoryList";

// Custom styles for DepartmentCard
function DepartmentCard({
  color,
  title,
  description,
  users,
  categories,
  selectedCompany,
  selectedDepartment,
}) {
  console.log("selectedDepartment", selectedDepartment)
  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{
              p: 1,
              mt: -6,
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
            }}
          >
            <Icon fontSize="large">desk</Icon>
          </MDAvatar>
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </MDTypography>

            <MDTypography variant="button" fontWeight="light" color="text">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider></Divider>

        <MDBox display="flex" justifyContent="space-between">
          <CategoryList
            title={t("CATEGORIES")}
            data={categories}
            selectedCompany={selectedCompany}
            selectedDepartment={selectedDepartment}
            formType="category"
          />
          <CategoryList
            title={t("USERS")}
            data={users}
            selectedCompany={selectedCompany}
            selectedDepartment={selectedDepartment}
            formType="user"
          />
        </MDBox>

        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {categories.length > -1 ? (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="medium">
                {categories.length}
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
              >
                Kategori
              </MDTypography>
            </MDBox>
          ) : null}
          {users.length > -1 ? (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="medium">
              {users.length}
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
              >
                Kullanıcı
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DepartmentCard
DepartmentCard.defaultProps = {
  color: "dark",
  dateTime: "",
  users: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
DepartmentCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  users: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default DepartmentCard;
