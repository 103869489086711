const locale = {
  deneme: "deneme 123 ing",
  ID: "ID",
  sign_in_header: "Giriş Yap",
  sign_in_message: "Email ve şifrenizi girerek giriş yapınız",
  email: "Email",
  password: "Şifre",
  remember_me: "Beni hatırla",
  dont_have_account: "Hesabınız yok mu?",
  sign_in: "Giriş Yap",
  sign_up: "Kayıt ol",
  sign_up_header: "Kayıt Ol",
  sign_up_message: "Bilgilerinizi girerek kayıt olunuz",
  name: "Adınız",
  i_agree: "Şartları ve koşulları",
  terms_and_conditions: "kabul ediyorum",
  already_have_account: "Zaten hesabınız var mı?",
  ADD_NEW: "Yeni Ekle",
  COMPANIES: "Şirketler",
  DEPARTMENTS: "Bölümler",
  CATEGORIES: "Kategoriler",
  SEARCH: "Ara",
  EDIT: "Düzenle",
  SAVE: "Kaydet",
  CANCEL: "İptal",
  NAME: "Ad",
  DESCRIPTION: "Açıklama",
  SELECT_COMPANY: "Şirket Seçiniz",
  COMPANIES_DEPARTMENT_HEADER: "Şirkete Bağlı Departmanlar",
  COMPANIES_DEPARTMENT_DESCRIPTION:
    "Şirkete bağlı departmanları görüntüleyin. Yeni Kategoriler ve Kullanılar yetkilendirin.",
  ATTACH_DEPARTMENT_FOR_COMPANY: "Departman Yetkilendirmesi",
  SELECT_DEPARTMENT_FOR_COMPANY:
    "Şirkete yetkilendirmek için departman seçiniz",
  USERS: "Kullanıcılar",
  SELECT_CATEGORY_FOR_DEPARTMENT: "Departman seçiniz",
  SELECT_USER_FOR_DEPARTMENT:"Kullanıcı Seçiniz"
};

export default locale;
