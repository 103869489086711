import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";

import { useForm } from "features/hooks";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateCompany } from "store/companiesSlice";
import { updateCategory } from "store/categoriesSlice";
import { updateDepartment } from "store/departmentsSlice";
import companiesData from "dummy/companiesData";

import FormField from "layouts/applications/wizard/components/FormField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
const defaultFormState = {
  id: "",
  name: "",
  description: "",
  status: "",
};

const EditObjectForm = ({
  id,
  name,
  description,
  status,
  closeEditCardForm,
  setEditCardForm,
  formType,
}) => {
  const userName = useSelector((state) => state.auth.userName);
  const dispatch = useDispatch();
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { t } = useTranslation();

  const [value, setValue] = useState({
    id: status,
    name: status,
  });
  const handleEditItem = () => {
    console.log("tofm type", formType);
    switch (formType) {
      case "category":
        let categoryData = {
          ...form,
          categoryName: form.name,
          categoryDescription: form.description,
          categoryId: id,
          status: value.id
        };

        dispatch(updateCategory(categoryData));
        break;

      case "company":
        let companyData = {
          ...form,
          companyName: form.name,
          companyDescription: form.description,
          companyId: id,
          status: value.id
        };

        console.log();
        dispatch(updateCompany(companyData));
        break;

      case "department":
        let departmentData = {
          ...form,
          departmentName: form.name,
          departmentDescription: form.description,
          departmentId: id,
          status: value.id
        };

        dispatch(updateDepartment(departmentData));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setForm({ id, name, description, status });
  }, [id]);

  const statusOptions = [
    {id:"aktif", name:"aktif"},
    {id:"pasif", name:"pasif"},
  ]
  return (
    <>
      <MDBox my={2.5}>
        <MDBox>
          <MDInput
            value={form.name}
            id="name"
            name="name"
            onChange={handleChange}
            fullWidth
            label={t("NAME")}
          />
        </MDBox>
        <MDBox mt={2}>
          <MDInput
            value={form.description}
            id="description"
            name="description"
            onChange={handleChange}
            fullWidth
            label={t("DESCRIPTION")}
          />
        </MDBox>
        <MDBox mt={2}>
          <Autocomplete
            value={value}
            options={statusOptions}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // fullWidth
            renderInput={(params) => (
              <TextField {...params} label={t("STATUS")} />
            )}
            onChange={(event, newValue) => {
              if (typeof newValue.name === "string") {
                setValue({
                  id: newValue.id,
                  name: newValue.name,
                });
              } else if (newValue && newValue.inputValue) {
                setValue({
                  name: newValue.inputValue,
                });
              } else {
                setValue(newValue);
              }
            }}
          />
        </MDBox>
        <MDBox display="flex" mt={2}>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              // addCard({ id: uuidv4(), template: formValue });
              handleEditItem();
              setForm(defaultFormState);
              closeEditCardForm();
            }}
          >
            {t("EDIT")}
          </MDButton>
          <MDBox ml={1}>
            <MDButton
              variant="gradient"
              color="light"
              size="small"
              onClick={closeEditCardForm}
            >
              {t("CANCEL")}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

EditObjectForm.propTypes = {};

export default EditObjectForm;
