import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from "@reduxjs/toolkit";
  import axiosConfig from "features/api/axiosConfig";
  import { toastr } from "react-redux-toastr";
  
  export const getUserAuths = createAsyncThunk(
    "userAuths/getUserAuths",
    async () => {
      const response = await axiosConfig.get(`/api/userAuths`);
      // const response = await axios.get(`${proxy}/api/userAuths`)
  
      let { data } = await response.data;
      return data;
    } 
  );
  export const getUserAuthsByCompany = createAsyncThunk(
    "userAuths/getUserAuthsByCompany",
    async (companyId) => {
      const response = await axiosConfig.get(
        `/api/user-auths/companies/${companyId}`
      );
      // const response = await axios.get(`${proxy}/api/userAuths`)
  
      let { data } = await response.data;
      return data;
    }
  );
  export const addUserAuth = createAsyncThunk(
    "userAuths/addUserAuth",
    async (userAuth, { dispatch, getState }) => {
      try {
        const response = await axiosConfig.post(
          `/api/user-auths`,
          userAuth
        );
        let { data } = await response.data;
        if (response.data.success === true) {
          toastr.success("Başarılı", "Kayıt Eklendi");
          console.log("getState", getState);
          return data;
        }
      } catch (error) {
        toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
        return null;
      }
    }
  );
  
  export const updateUserAuth = createAsyncThunk(
    "userAuths/updateUserAuth",
    async (params, { dispatch, getState }) => {
      const response = await axiosConfig.put(
        `/api/user-auths/${params.companyId}/${params.departmentId}/${params.categoryId}`,
        params
      );
      const { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Güncellendi");
        return data;
      }
      return null;
    }
  );
  
  export const removeUserAuth = createAsyncThunk(
    "userAuths/removeUserAuth",
    async (params, { dispatch, getState }) => {
      let response = await axiosConfig.delete(
        `/api/user-auths/${params.companyId}/${params.departmentId}/${params.categoryId}`
      );
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Silindi");
        return params.companyId && params.departmentId;
      }
      return params;
    }
  );
  
  const userAuthsAdapter = createEntityAdapter({
    selectId: (userAuth) => userAuth.combineKey,
  });
  
  export const {
    selectAll: selectuserAuths,
    selectById: selectuserAuthsById,
  } = userAuthsAdapter.getSelectors(
    (state) => state.userAuths
  );
  
  const userAuthsSlice = createSlice({
    name: "userAuths",
    initialState: userAuthsAdapter.getInitialState({
      searchText: "",
      routeParams: {},
      userAuthDialog: {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      },
      confirmDialog: {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      },
    }),
    reducers: {
      setUserAuthSearchText: {
        reducer: (state, action) => {
          state.searchText = action.payload;
        },
        prepare: (event) => ({ payload: event.target.value || "" }),
      },
      openNewUserAuthDialog: (state, action) => {
        state.userAuthDialog = {
          type: "new",
          props: {
            isOpen: true,
          },
          data: null,
        };
      },
      closeNewUserAuthDialog: (state, action) => {
        state.userAuthDialog = {
          type: "new",
          props: {
            isOpen: false,
          },
          data: null,
        };
      },
      openEditUserAuthDialog: (state, action) => {
        state.userAuthDialog = {
          type: "edit",
          props: {
            isOpen: true,
          },
          data: action.payload,
        };
      },
      closeEditUserAuthDialog: (state, action) => {
        state.userAuthDialog = {
          type: "edit",
          props: {
            isOpen: false,
          },
          data: null,
        };
      },
      openConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            open: true,
          },
          data: action.payload,
        };
      },
      closeConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            isOpen: false,
          },
          data: action.payload,
        };
      },
    },
    extraReducers: {
      [updateUserAuth.fulfilled]: userAuthsAdapter.upsertOne,
      [addUserAuth.fulfilled]: userAuthsAdapter.addOne,
      [removeUserAuth.fulfilled]: (state, action) =>
        userAuthsAdapter.removeOne(state, action.payload),
      [getUserAuths.fulfilled]: userAuthsAdapter.setAll,
      [getUserAuthsByCompany.fulfilled]:
        userAuthsAdapter.setAll,
    },
  });
  
  export const {
    setUserAuthSearchText,
    openNewUserAuthDialog,
    closeNewUserAuthDialog,
    openEditUserAuthDialog,
    closeEditUserAuthDialog,
    openConfirmDialog,
    closeConfirmDialog,
    openResetPasswordDialog,
    closeResetPasswordDialog,
  } = userAuthsSlice.actions;
  
  export default userAuthsSlice.reducer;
  