const locale = {
  deneme: "deneme 123 ing",
  ID: "ID",
  sign_in_header: "Einloggen",
  sign_in_message: "Geben Sie Ihre E-Mail-Adresse und Passfor für die Anmeldung ein",
  email: "Email",
  password: "Passwort",
  remember_me: "Erinnere dich an mich",
  dont_have_account: "",
  sign_in: "Einloggen",
  sign_up: "Anmelden",
  sign_up_header: "Anmelden",
  sign_up_message: "Geben Sie Ihre Zugangsdaten ein, um sich zu registrieren",
  name: "Name",
  i_agree: "Ich stimme zu",
  terms_and_conditions: "Geschäftsbedingungen",
  already_have_account: "Haben Sie bereits ein Konto?",
};

export default locale;
