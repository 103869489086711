import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from "@reduxjs/toolkit";
  import axiosConfig from "features/api/axiosConfig";
  import { toastr } from "react-redux-toastr";
  export const getUsers = createAsyncThunk(
    "users/getUsers",
    async () => {
      const response = await axiosConfig.get(`/api/users`);
      // const response = await axios.get(`${proxy}/api/users`)
  
      let { data } = await response.data;
      return data;
    }
  );
  
  export const getUsersWithoutDepartments = createAsyncThunk(
    "departments/getUsersWithoutDepartments",
    async (params) => {
      const response = await axiosConfig.get(
        `/api/users/without-department/${params.companyId}/${params.departmentId}`
      );
      // const response = await axios.get(`${proxy}/api/departments`)
      let { data } = await response.data;
      return data;
    }
  );
  
  export const addUser = createAsyncThunk(
    "users/addUser",
    async (user, { dispatch, getState }) => {
      try {
        const response = await axiosConfig.post(`/api/users`, user);
        let { data } = await response.data;
        if (response.data.success === true) {
          toastr.success("Başarılı", "Kayıt Eklendi");
          return data;
        }
      } catch (error) {
        toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
        return null;
      }
    }
  );
  
  export const updateUser = createAsyncThunk(
    "users/updateUser",
    async (user, { dispatch, getState }) => {
      const response = await axiosConfig.put(
        `/api/users/${user.userName}`,
        user
      );
      const { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Güncellendi");
        return data;
      }
      return null;
    }
  );
  
  export const removeUser = createAsyncThunk(
    "users/removeUser",
    async (userName, { dispatch, getState }) => {
      let response = await axiosConfig.delete(`/api/users/${userName}`);
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Silindi");
        return userName;
      }
      return userName;
    }
  );
  
  const usersAdapter = createEntityAdapter({
    selectId: (user) => user.userName,
  });
  
  export const { selectAll: selectusers, selectById: selectusersById } =
    usersAdapter.getSelectors((state) => state.users);
  
  const usersSlice = createSlice({
    name: "users",
    initialState: usersAdapter.getInitialState({
      searchText: "",
      routeParams: {},
      userDialog: {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      },
      confirmDialog: {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      },
    }),
    reducers: {
      setUserSearchText: {
        reducer: (state, action) => {
          state.searchText = action.payload;
        },
        prepare: (event) => ({ payload: event.target.value || "" }),
      },
      openNewUserDialog: (state, action) => {
        state.userDialog = {
          type: "new",
          props: {
            isOpen: true,
          },
          data: null,
        };
      },
      closeNewUserDialog: (state, action) => {
        state.userDialog = {
          type: "new",
          props: {
            isOpen: false,
          },
          data: null,
        };
      },
      openEditUserDialog: (state, action) => {
        state.userDialog = {
          type: "edit",
          props: {
            isOpen: true,
          },
          data: action.payload,
        };
      },
      closeEditUserDialog: (state, action) => {
        state.userDialog = {
          type: "edit",
          props: {
            isOpen: false,
          },
          data: null,
        };
      },
      openConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            open: true,
          },
          data: action.payload,
        };
      },
      closeConfirmDialog: (state, action) => {
        state.confirmDialog = {
          type: "new",
          props: {
            isOpen: false,
          },
          data: action.payload,
        };
      },
    },
    extraReducers: {
      [updateUser.fulfilled]: usersAdapter.upsertOne,
      [addUser.fulfilled]: usersAdapter.addOne,
      [removeUser.fulfilled]: (state, action) =>
        usersAdapter.removeOne(state, action.payload),
      [getUsers.fulfilled]: usersAdapter.setAll,
      [getUsersWithoutDepartments.fulfilled]: usersAdapter.setAll,
    },
  });
  
  export const {
    setUserSearchText,
    openNewUserDialog,
    closeNewUserDialog,
    openEditUserDialog,
    closeEditUserDialog,
    openConfirmDialog,
    closeConfirmDialog,
    openResetPasswordDialog,
    closeResetPasswordDialog,
  } = usersSlice.actions;
  
  export default usersSlice.reducer;
  