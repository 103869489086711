import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import { toastr } from "react-redux-toastr";
export const getDepartments = createAsyncThunk(
  "departments/getDepartments",
  async () => {
    const response = await axiosConfig.get(`/api/departments`);
    // const response = await axios.get(`${proxy}/api/departments`)

    let { data } = await response.data;
    return data;
  }
);
export const getDepartmentsWithoutCompanies = createAsyncThunk(
  "departments/getDepartmentsWithoutCompanies",
  async (companyId) => {
    const response = await axiosConfig.get(
      `/api/departments/without-company/${companyId}`
    );
    // const response = await axios.get(`${proxy}/api/departments`)

    let { data } = await response.data;
    return data;
  }
);

export const addDepartment = createAsyncThunk(
  "departments/addDepartment",
  async (department, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/departments`, department);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
      return null;
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "departments/updateDepartment",
  async (department, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/departments/${department.departmentId}`,
      department
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    }
    return null;
  }
);

export const removeDepartment = createAsyncThunk(
  "departments/removeDepartment",
  async (departmentId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/departments/${departmentId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return departmentId;
    }
    return departmentId;
  }
);

const departmentsAdapter = createEntityAdapter({
  selectId: (department) => department.departmentId,
});

export const {
  selectAll: selectdepartments,
  selectById: selectdepartmentsById,
} = departmentsAdapter.getSelectors((state) => state.departments);

const departmentsSlice = createSlice({
  name: "departments",
  initialState: departmentsAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    departmentDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
  }),
  reducers: {
    setDepartmentSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDepartmentDialog: (state, action) => {
      state.departmentDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewDepartmentDialog: (state, action) => {
      state.departmentDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openEditDepartmentDialog: (state, action) => {
      state.departmentDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDepartmentDialog: (state, action) => {
      state.departmentDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateDepartment.fulfilled]: departmentsAdapter.upsertOne,
    [addDepartment.fulfilled]: departmentsAdapter.addOne,
    [removeDepartment.fulfilled]: (state, action) =>
      departmentsAdapter.removeOne(state, action.payload),
    [getDepartments.fulfilled]: departmentsAdapter.setAll,
    [getDepartmentsWithoutCompanies.fulfilled]: departmentsAdapter.setAll,
  },
});

export const {
  setDepartmentSearchText,
  openNewDepartmentDialog,
  closeNewDepartmentDialog,
  openEditDepartmentDialog,
  closeEditDepartmentDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
