const locale = {
  deneme: "deneme 123",
  ID: "ID",
  sign_in_header: "Sign In",
  sign_in_message: "Enter your email and passfor for login",
  email: "Email",
  password: "Password",
  remember_me: "Remember Me",
  dont_have_account: "Don't have account?",
  sign_in: "Sign In",
  sign_up: "Sign Up",
  sign_up_header: "Sign Up",
  sign_up_message: "Enter your credentials to register",
  name: "Name",
  i_agree: "I agree",
  terms_and_conditions: "terms and conditions",
  already_have_account: "Adready have account?",
  ADD_NEW: "Add New",
  COMPANIES: "Companies",
  DEPARTMENTS: "Departments",
  CATEGORIES: "Categories",
  SEARCH: "Search",
  EDIT: "Edit",
  SAVE: "Save",
  CANCEL: "Cancel",
  NAME: "Name",
  DESCRIPTION: "Description",
  SELECT_COMPANY: "Select Company",
  COMPANIES_DEPARTMENT_HEADER: "Affiliated Departments of the Company",
  COMPANIES_DEPARTMENT_DESCRIPTION:
    "View the departments affiliated to the company. Authorize New Categories and Users.",
  ATTACH_DEPARTMENT_FOR_COMPANY: "Department Authorization",
  SELECT_DEPARTMENT_FOR_COMPANY:
    "Select the department to authorize the company",
  USERS: "Users",
  SELECT_CATEGORY_FOR_DEPARTMENT: "Select category",

  SELECT_USER_FOR_DEPARTMENT: "Select user",
};

export default locale;
