import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import { toastr } from "react-redux-toastr";
export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async () => {
    const response = await axiosConfig.get(`/api/companies`);
    // const response = await axios.get(`${proxy}/api/companies`)

    let { data } = await response.data;
    return data;
  }
);

export const addCompany = createAsyncThunk(
  "companies/addCompany",
  async (company, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(`/api/companies`, company);
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        return data;
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
      return null;
    }
  }
);

export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async (company, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/companies/${company.companyId}`,
      company
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    }
    return null;
  }
);

export const removeCompany = createAsyncThunk(
  "companies/removeCompany",
  async (companyId, { dispatch, getState }) => {
    let response = await axiosConfig.delete(`/api/companies/${companyId}`);
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return companyId;
    }
    return companyId;
  }
);

const companiesAdapter = createEntityAdapter({
  selectId: (company) => company.companyId,
});

export const { selectAll: selectcompanies, selectById: selectcompaniesById } =
  companiesAdapter.getSelectors((state) => state.companies);

const companiesSlice = createSlice({
  name: "companies",
  initialState: companiesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    companyDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
  }),
  reducers: {
    setCompanySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewCompanyDialog: (state, action) => {
      state.companyDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewCompanyDialog: (state, action) => {
      state.companyDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openEditCompanyDialog: (state, action) => {
      state.companyDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditCompanyDialog: (state, action) => {
      state.companyDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateCompany.fulfilled]: companiesAdapter.upsertOne,
    [addCompany.fulfilled]: companiesAdapter.addOne,
    [removeCompany.fulfilled]: (state, action) =>
      companiesAdapter.removeOne(state, action.payload),
    [getCompanies.fulfilled]: companiesAdapter.setAll,
  },
});

export const {
  setCompanySearchText,
  openNewCompanyDialog,
  closeNewCompanyDialog,
  openEditCompanyDialog,
  closeEditCompanyDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = companiesSlice.actions;

export default companiesSlice.reducer;
