import React from "react";
import DashboardLayout from "pages/main/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/main/DashboardNavbar";

import MDBox from "components/MDBox";
import MainFooter from "../footer/MainFooter";
const AdminLayout = ({ children }) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      
      {children}
      <MainFooter />
    </DashboardLayout>
  );
};

AdminLayout.propTypes = {};

export default AdminLayout;
