import { useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon";
import { useTranslation } from "react-i18next";

import { useForm } from "features/hooks";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "store/categoriesSlice";
import { addCompany } from "store/companiesSlice";
import { addDepartment } from "store/departmentsSlice";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormField from "layouts/applications/wizard/components/FormField";
import { Grid, Typography } from "@mui/material";
import { addCompanyDepartment } from "store/companyDepartmentsSlice";
import { getCompanyDepartmentsByCompany } from "store/companyDepartmentsSlice";
import { getDepartmentsWithoutCompanies } from "store/departmentsSlice";
import { addDepartmentCategory } from "store/departmentCategoriesSlice";
import { getDepartmentCategoriesByCompany } from "store/departmentCategoriesSlice";
import { getCategoriesWithoutDepartments } from "store/categoriesSlice";
import { addUserAuth } from "store/userAuthsSlice";
import { getUserAuthsByCompany } from "store/userAuthsSlice";
import { getUsersWithoutDepartments } from "store/usersSlice";

const defaultFormState = {
  companyId: 0,
  departmentId: 0,
  id: 0,
  name: "",
  status: "aktif",
};

const AddNewObjectFormWithSelectAndButton = ({
  title,
  id,
  formType,
  companyId,
  departmentId,
  optionsData,
  selectText,
  newCardForm,
  setNewCardForm,
  formHeader,
}) => {
  const dispatch = useDispatch();

  const userName = useSelector((state) => state.auth.userName);
  const { form, handleChange, setForm, setInForm } = useForm(defaultFormState);
  const { t } = useTranslation();

  const closeNewCardForm = () => setNewCardForm(false);

  const handleAddNewItem = () => {
    let data = { ...form, createdBy: userName };

    switch (formType) {
      case "category":
        let categoryData = {
          ...form,
          createdBy: userName,
          companyId,
          departmentId,
          categoryId: form.id,
        };

        console.log("categoryData", categoryData);
        dispatch(addDepartmentCategory(categoryData)).then(() => {
          dispatch(getDepartmentCategoriesByCompany(companyId));
          dispatch(
            getCategoriesWithoutDepartments({
              companyId,
              departmentId,
            })
          );
        });
        break;

      case "user":
        let userData = {
          ...form,
          createdBy: userName,
          companyId,
          departmentId,
          userName: form.id,
        };

        console.log("userData", userData);
        dispatch(addUserAuth(userData)).then(() => {
          dispatch(getUserAuthsByCompany(companyId));
          dispatch(
            getUsersWithoutDepartments({
              companyId,
              departmentId,
            })
          );
        });
        break;

      case "companyDepartment":
        let departmentData = {
          ...form,
          createdBy: userName,
          companyId,
          departmentId: form.id,
        };
        console.log("departmentDAta", departmentData);
        dispatch(addCompanyDepartment(departmentData)).then(() => {
          dispatch(getCompanyDepartmentsByCompany(companyId));
          dispatch(getDepartmentsWithoutCompanies(companyId));
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <MDTypography variant="h6">{t("ADD_NEW")}</MDTypography>
        <MDButton size="small" iconOnly onClick={(event) => setNewCardForm(id)}>
          <Icon
            sx={{
              fontWeight: "bold",
              color: ({ palette: { dark } }) => dark.main,
            }}
          >
            add
          </Icon>
        </MDButton>
      </MDBox>
      {newCardForm === id ? (
        <Grid item>
          <MDBox my={2.5}>
            <MDTypography variant="h5">{formHeader}</MDTypography>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <Autocomplete
                value={form}
                options={optionsData}
                getOptionLabel={(option) => option.name}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label={selectText}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue.name === "string") {
                    setForm({
                      id: newValue.id,
                      name: newValue.name,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setForm({
                      name: newValue.inputValue,
                    });
                  } else {
                    setForm(newValue);
                  }
                }}
              />
            </MDBox>
            <MDBox display="flex" mt={2}>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  // addCard({ id: uuidv4(), template: formValue });
                  handleAddNewItem();
                  setForm(defaultFormState);
                  closeNewCardForm();
                }}
              >
                {t("ADD")}
              </MDButton>
              <MDBox ml={1}>
                <MDButton
                  variant="gradient"
                  color="light"
                  size="small"
                  onClick={closeNewCardForm}
                >
                  {t("CANCEL")}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      ) : null}
    </>
  );
};

AddNewObjectFormWithSelectAndButton.propTypes = {};

export default AddNewObjectFormWithSelectAndButton;
