import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axiosConfig from "features/api/axiosConfig";
import { toastr } from "react-redux-toastr";

export const getDepartmentCategories = createAsyncThunk(
  "departmentCategories/getDepartmentCategories",
  async () => {
    const response = await axiosConfig.get(`/api/departmentCategories`);
    // const response = await axios.get(`${proxy}/api/departmentCategories`)

    let { data } = await response.data;
    return data;
  }
);
export const getDepartmentCategoriesByCompany = createAsyncThunk(
  "departmentCategories/getDepartmentCategoriesByCompany",
  async (companyId) => {
    const response = await axiosConfig.get(
      `/api/department-categories/companies/${companyId}`
    );
    // const response = await axios.get(`${proxy}/api/departmentCategories`)

    let { data } = await response.data;
    return data;
  }
);
export const addDepartmentCategory = createAsyncThunk(
  "departmentCategories/addDepartmentCategory",
  async (departmentCategory, { dispatch, getState }) => {
    try {
      const response = await axiosConfig.post(
        `/api/department-categories`,
        departmentCategory
      );
      let { data } = await response.data;
      if (response.data.success === true) {
        toastr.success("Başarılı", "Kayıt Eklendi");
        console.log("getState", getState);
        return data;
      }
    } catch (error) {
      toastr.error("Hata", "Bir hata oluştu. Lütfen tekrar deneyiniz");
      return null;
    }
  }
);

export const updateDepartmentCategory = createAsyncThunk(
  "departmentCategories/updateDepartmentCategory",
  async (params, { dispatch, getState }) => {
    const response = await axiosConfig.put(
      `/api/department-categories/${params.companyId}/${params.departmentId}/${params.categoryId}`,
      params
    );
    const { data } = await response.data;
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Güncellendi");
      return data;
    }
    return null;
  }
);

export const removeDepartmentCategory = createAsyncThunk(
  "departmentCategories/removeDepartmentCategory",
  async (params, { dispatch, getState }) => {
    let response = await axiosConfig.delete(
      `/api/department-categories/${params.companyId}/${params.departmentId}/${params.categoryId}`
    );
    if (response.data.success === true) {
      toastr.success("Başarılı", "Kayıt Silindi");
      return params.companyId && params.departmentId;
    }
    return params;
  }
);

const departmentCategoriesAdapter = createEntityAdapter({
  selectId: (departmentCategory) => departmentCategory.combineKey,
});

export const {
  selectAll: selectdepartmentCategories,
  selectById: selectdepartmentCategoriesById,
} = departmentCategoriesAdapter.getSelectors(
  (state) => state.departmentCategories
);

const departmentCategoriesSlice = createSlice({
  name: "departmentCategories",
  initialState: departmentCategoriesAdapter.getInitialState({
    searchText: "",
    routeParams: {},
    departmentCategoryDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
    confirmDialog: {
      type: "new",
      props: {
        isOpen: false,
      },
      data: null,
    },
  }),
  reducers: {
    setDepartmentCategorySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
    openNewDepartmentCategoryDialog: (state, action) => {
      state.departmentCategoryDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewDepartmentCategoryDialog: (state, action) => {
      state.departmentCategoryDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openEditDepartmentCategoryDialog: (state, action) => {
      state.departmentCategoryDialog = {
        type: "edit",
        props: {
          isOpen: true,
        },
        data: action.payload,
      };
    },
    closeEditDepartmentCategoryDialog: (state, action) => {
      state.departmentCategoryDialog = {
        type: "edit",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeConfirmDialog: (state, action) => {
      state.confirmDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: action.payload,
      };
    },
  },
  extraReducers: {
    [updateDepartmentCategory.fulfilled]: departmentCategoriesAdapter.upsertOne,
    [addDepartmentCategory.fulfilled]: departmentCategoriesAdapter.addOne,
    [removeDepartmentCategory.fulfilled]: (state, action) =>
      departmentCategoriesAdapter.removeOne(state, action.payload),
    [getDepartmentCategories.fulfilled]: departmentCategoriesAdapter.setAll,
    [getDepartmentCategoriesByCompany.fulfilled]:
      departmentCategoriesAdapter.setAll,
  },
});

export const {
  setDepartmentCategorySearchText,
  openNewDepartmentCategoryDialog,
  closeNewDepartmentCategoryDialog,
  openEditDepartmentCategoryDialog,
  closeEditDepartmentCategoryDialog,
  openConfirmDialog,
  closeConfirmDialog,
  openResetPasswordDialog,
  closeResetPasswordDialog,
} = departmentCategoriesSlice.actions;

export default departmentCategoriesSlice.reducer;
