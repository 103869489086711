import React from "react";

import AdminLayout from "pages/main/layout/AdminLayout";

const CategoriesDashboard = (props) => {
  return (
    <AdminLayout>
      <h3>Songs </h3>
      <p>Sons adding updating vs.</p>
    </AdminLayout>
  );
};

CategoriesDashboard.propTypes = {};

export default CategoriesDashboard;
